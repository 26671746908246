export enum RoutesEnum {
  LOGIN_PATH = 'login',
  MAKE_REQUEST_PATH = 'make-request',
  VACATION_REQUEST_PATH = 'vacation',
  WORKIN_FORM_OFFICE_REQUEST_PATH = 'working-from-office-request',
  ONSITE_REQUEST_PATH = 'onsite-request',
  BOOK_NOMAD_ROOM_REQUEST_PATH = 'book-nomad-room-request',
  PROFILE_PATH = 'profile',
  HOLIDAYS_ADMIN_PATH = 'holidays-admin',
  COMPANY_ADMIN_PATH = 'company-admin',
  DISPLAY_GENERAL_PATH = 'display-general',
  USER_ADMIN_PATH = 'user-admin',
  DASHBOARD_REGULAR_USER_PATH = 'dashboard-regular-user',
  LOCATION_ADMIN_PATH = 'location-admin',
  MINDIT_TEAM_TAB_PATH = 'mindit-team-tab',
  MINDIT_TEAM_PATH = 'mindit-team',
  MINDIT_ORG_CHART_PATH = 'mindit-org-chart',
  NOMAD_PATH = 'nomad',
  TEAM_ADMIN_PATH = 'team-admin',
  UNKNOWN_PATH = '**',
  ADMIN_DASHBOARD_PATH = 'admin-dashboard',
  ALL_REQUESTS = 'all-requests',
  REQUEST_ADMINISTRATION = 'request-administration',
  PROFILE_MANAGEMENT_PATH = 'profile-management',
  MY_CV_PATH = 'my-cv',
  CALENDARS_PATH = 'calendars',
  CLASSIC_CALENDAR_PATH = 'classic-calendar',
  SELECTION_CALENDAR_PATH = 'selection-calendar',
  NOMAD_ADMIN_PATH = 'nomad-admin'
}
