/**
 * OpenAPI definition
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { UserDTO } from './userDTO';
import { RequestTypeDTO } from './requestTypeDTO';
import { LocationDTO } from './locationDTO';
import { RequestResponseTypeDTO } from './requestResponseTypeDTO';


export interface HolidayRequestDTO { 
    id?: number;
    user?: UserDTO;
    requestType?: RequestTypeDTO;
    startDate?: string;
    endDate?: string;
    responseType?: RequestResponseTypeDTO;
    location?: LocationDTO;
    message?: string;
    createdAt?: string;
    spentDays?: number;
    approvedBy?: UserDTO;
    managerName?: string;
    entitlementYear?: number;
    requestTypeEnum?: HolidayRequestDTO.RequestTypeEnumEnum;
}
export namespace HolidayRequestDTO {
    export type RequestTypeEnumEnum = 'VACATION' | 'WORKING_FROM_HOME' | 'MEDICAL_LEAVE' | 'UNPAID_LEAVE' | 'WORKING_ON_SITE' | 'MATERNITY_LEAVE' | 'WORKING_FROM_OFFICE' | 'PAID_LEAVE' | 'NOMAD' | 'WEEKEND' | 'PUBLIC_HOLIDAY' | 'NO_REQUEST' | 'FLEXI_DAYS' | 'MENTAL_HEALTH';
    export const RequestTypeEnumEnum = {
        Vacation: 'VACATION' as RequestTypeEnumEnum,
        WorkingFromHome: 'WORKING_FROM_HOME' as RequestTypeEnumEnum,
        MedicalLeave: 'MEDICAL_LEAVE' as RequestTypeEnumEnum,
        UnpaidLeave: 'UNPAID_LEAVE' as RequestTypeEnumEnum,
        WorkingOnSite: 'WORKING_ON_SITE' as RequestTypeEnumEnum,
        MaternityLeave: 'MATERNITY_LEAVE' as RequestTypeEnumEnum,
        WorkingFromOffice: 'WORKING_FROM_OFFICE' as RequestTypeEnumEnum,
        PaidLeave: 'PAID_LEAVE' as RequestTypeEnumEnum,
        Nomad: 'NOMAD' as RequestTypeEnumEnum,
        Weekend: 'WEEKEND' as RequestTypeEnumEnum,
        PublicHoliday: 'PUBLIC_HOLIDAY' as RequestTypeEnumEnum,
        NoRequest: 'NO_REQUEST' as RequestTypeEnumEnum,
        FlexiDays: 'FLEXI_DAYS' as RequestTypeEnumEnum,
        MentalHealth: 'MENTAL_HEALTH' as RequestTypeEnumEnum
    };
}


