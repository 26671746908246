import { HolidaysResolver } from './shared/resolvers/holidays.resolver';
import { FiltersResolver } from './shared/resolvers/filters.resolver';
import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { RoutesEnum } from './shared/enums/routes.enum';
import { LayoutComponent } from './shared/layout/layout.component';
import { AuthorityGuard } from './core/guards/authority-guard.service';
import { AuthorityEnum } from './core/enums/authority.enum';
import { LoginGuard } from './core/guards/login.guard';

const routes: Routes = [
  { path: '', redirectTo: RoutesEnum.LOGIN_PATH, pathMatch: 'full' },
  {
    path: RoutesEnum.LOGIN_PATH,
    loadChildren: () =>
      import('./views/login-page/login-page.component').then(
        // eslint-disable-next-line @typescript-eslint/typedef
        (m) => m.LoginPageComponentModule
      ),
    canActivate: [LoginGuard]
  },
  {
    path: '',
    component: LayoutComponent,
    children: [
      {
        path: RoutesEnum.ADMIN_DASHBOARD_PATH,
        loadChildren: () =>
          import('./views/dashboard-admin/dashboard-admin.component').then(
            // eslint-disable-next-line @typescript-eslint/typedef
            (m) => m.DashboardAdminComponentModule
          ),
        resolve: {
          content: FiltersResolver
        },
        canActivate: [AuthorityGuard],
        data: {
          authorities: [AuthorityEnum.ADMIN, AuthorityEnum.MANAGER]
        }
      },
      {
        path: RoutesEnum.MAKE_REQUEST_PATH,
        loadChildren: () =>
          import(
            './views/make-request/make-request.component').then(
            // eslint-disable-next-line @typescript-eslint/typedef
            (m) => m.MakeRequestComponentModule
          ),
        canActivate: [AuthorityGuard],
        data: {
          authorities: [
            AuthorityEnum.REGULAR,
            AuthorityEnum.MANAGER,
            AuthorityEnum.ADMIN
          ]
        }
      },
      {
        path: RoutesEnum.PROFILE_PATH,
        loadChildren: () =>
          import('./views/profile/profile.component').then(
            // eslint-disable-next-line @typescript-eslint/typedef
            (m) => m.ProfileComponentModule
          ),
        canActivate: [AuthorityGuard],
        data: {
          authorities: [
            AuthorityEnum.REGULAR,
            AuthorityEnum.MANAGER,
            AuthorityEnum.ADMIN
          ]
        }
      },
      {
        path: RoutesEnum.HOLIDAYS_ADMIN_PATH,
        loadChildren: () =>
          import(
            './views/administration-holidays/administration-holidays.component'
            // eslint-disable-next-line @typescript-eslint/typedef
          ).then((m) => m.AdministrationHolidaysComponentModule),
        canActivate: [AuthorityGuard],
        data: {
          authorities: [AuthorityEnum.ADMIN]
        }
      },
      {
        path: RoutesEnum.COMPANY_ADMIN_PATH,
        loadChildren: () =>
          import(
            './views/company-administration-page/company-administration-page.component'
            // eslint-disable-next-line @typescript-eslint/typedef
          ).then((m) => m.CompanyAdministrationPageComponentModule),
        canActivate: [AuthorityGuard],
        data: {
          authorities: [AuthorityEnum.ADMIN]
        }
      },
      {
        path: RoutesEnum.ALL_REQUESTS,
        loadChildren: () =>
          import(
            './views/all-requests/all-requests.component'
            // eslint-disable-next-line @typescript-eslint/typedef
          ).then((m) => m.AllRequestsComponentModule)
      },
      {
        path: RoutesEnum.DISPLAY_GENERAL_PATH,
        loadChildren: () =>
          import(
            './views/display-general-page/display-general-page.component'
            // eslint-disable-next-line @typescript-eslint/typedef
          ).then((m) => m.DisplayGeneralPageComponentModule),
        canActivate: [AuthorityGuard],
        data: {
          authorities: [AuthorityEnum.ADMIN, AuthorityEnum.MANAGER]
        }
      },
      {
        path: RoutesEnum.USER_ADMIN_PATH,
        loadChildren: () =>
          import(
            './views/user-administration-page/user-administration-page.component'
            // eslint-disable-next-line @typescript-eslint/typedef
          ).then((m) => m.UserAdministrationPageComponentModule),
        canActivate: [AuthorityGuard],
        data: {
          authorities: [AuthorityEnum.ADMIN]
        }
      },
      {
        path: RoutesEnum.DASHBOARD_REGULAR_USER_PATH,
        loadChildren: () =>
          import(
            './views/dashboard-regular-user/dashboard-regular-user.component'
            // eslint-disable-next-line @typescript-eslint/typedef
          ).then((m) => m.DashboardRegularUserComponentModule),
        resolve: {
          content: HolidaysResolver
        },
        canActivate: [AuthorityGuard],
        data: {
          authorities: [
            AuthorityEnum.REGULAR,
            AuthorityEnum.ADMIN,
            AuthorityEnum.MANAGER
          ]
        }
      },
      {
        path: RoutesEnum.LOCATION_ADMIN_PATH,
        loadChildren: () =>
          import(
            './views/location-administration/location-administration.component'
            // eslint-disable-next-line @typescript-eslint/typedef
          ).then((m) => m.LocationAdministrationComponentModule),
        canActivate: [AuthorityGuard],
        data: {
          authorities: [AuthorityEnum.ADMIN]
        }
      },
      {
        path: RoutesEnum.MINDIT_TEAM_TAB_PATH,
        loadChildren: () =>
          import(
            './views/mindit-team-tab/mindit-team-tab.component').then(
            // eslint-disable-next-line @typescript-eslint/typedef
            (m) => m.MinditTeamTabComponentModule
          ),
        canActivate: [AuthorityGuard],
        data: {
          authorities: [
            AuthorityEnum.REGULAR,
            AuthorityEnum.MANAGER,
            AuthorityEnum.ADMIN
          ]
        }
      },
      {
        path: `${RoutesEnum.NOMAD_PATH}/:id`,
        loadChildren: () =>
          import(
            './views/nomad-office/nomad-office.component'
            // eslint-disable-next-line @typescript-eslint/typedef
          ).then((m) => m.NomadOfficeComponentModule),
        canActivate: [AuthorityGuard],
        data: {
          authorities: [
            AuthorityEnum.REGULAR,
            AuthorityEnum.MANAGER,
            AuthorityEnum.ADMIN
          ]
        }
      },
      {
        path: RoutesEnum.TEAM_ADMIN_PATH,
        loadChildren: () =>
          import(
            './views/team-administration-page/team-administration-page.component'
            // eslint-disable-next-line @typescript-eslint/typedef
          ).then((m) => m.TeamAdministrationPageComponentModule),
        canActivate: [AuthorityGuard],
        data: {
          authorities: [AuthorityEnum.ADMIN]
        }
      },
      {
        path: RoutesEnum.NOMAD_ADMIN_PATH,
        loadChildren: () =>
          import(
            './views/nomad-administration-page/nomad-administration-page.component'
            // eslint-disable-next-line @typescript-eslint/typedef
          ).then((m) => m.NomadAdministrationPageComponentModule),
        canActivate: [AuthorityGuard],
        data: {
          authorities: [AuthorityEnum.ADMIN]
        }
      },
      // ipp-300 Hide Profile Management until its requested
      // {
      //   path: RoutesEnum.PROFILE_MANAGEMENT_PATH,
      //   loadChildren: () =>
      //     import(
      //       './views/profile-management/profile-management.component'
      //       // eslint-disable-next-line @typescript-eslint/typedef
      //     ).then((m) => m.ProfileManagementComponentModule),
      //   canActivate: [AuthorityGuard],
      //   data: {
      //     authorities: [
      //       AuthorityEnum.REGULAR,
      //       AuthorityEnum.MANAGER,
      //       AuthorityEnum.ADMIN
      //     ]
      //   }
      // },
      {
        path: RoutesEnum.REQUEST_ADMINISTRATION,
        loadChildren: () =>
          import(
            './views/request-administration-page/request-administration-page.component'
            // eslint-disable-next-line @typescript-eslint/typedef
          ).then((m) => m.RequestAdministrationPageComponentModule),
        canActivate: [AuthorityGuard],
        data: {
          authorities: [
            AuthorityEnum.REGULAR,
            AuthorityEnum.MANAGER,
            AuthorityEnum.ADMIN
          ]
        }
      },
      {
        path: RoutesEnum.CALENDARS_PATH,
        loadChildren: () =>
          import('./views/calendars/calendars.component').then(
            // eslint-disable-next-line @typescript-eslint/typedef
            (m) => m.CalendarsComponentModule
          ),
        canActivate: [AuthorityGuard],
        data: {
          authorities: [
            AuthorityEnum.REGULAR,
            AuthorityEnum.MANAGER,
            AuthorityEnum.ADMIN
          ]
        }
      },
      {
        path: RoutesEnum.UNKNOWN_PATH,
        redirectTo: RoutesEnum.HOLIDAYS_ADMIN_PATH,
        pathMatch: 'full'
      }
    ]
  },
  {
    path: RoutesEnum.UNKNOWN_PATH,
    redirectTo: '/' + RoutesEnum.LOGIN_PATH
  }
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
