/**
 * OpenAPI definition
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


export interface LocationDTO { 
    id?: number;
    name?: string;
    locationType?: LocationDTO.LocationTypeEnum;
    isActive?: boolean;
    seatCapacity?: number;
    nomadRooms?: number;
    address?: string;
    link?: string;
    description?: string;
    availableFromMonth?: number;
    availableFromDay?: number;
    availableToMonth?: number;
    availableToDay?: number;
}
export namespace LocationDTO {
    export type LocationTypeEnum = 'OFFICE' | 'NOMAD' | 'MIXED';
    export const LocationTypeEnum = {
        Office: 'OFFICE' as LocationTypeEnum,
        Nomad: 'NOMAD' as LocationTypeEnum,
        Mixed: 'MIXED' as LocationTypeEnum
    };
}


