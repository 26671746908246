import { RoutesEnum } from '../enums/routes.enum';
import { ISidebarMenu } from './../interfaces/sidebar-menu.interface';
import { AuthorityEnum } from '../../core/enums/authority.enum';

export const listItemsMenu: ISidebarMenu[] = [
  {
    name: 'dashboard',
    label: 'Dashboard',
    icon: 'dashboard',
    width: '30',
    height: '30',
    isHovered: false,
    visibleTo: [
      AuthorityEnum.REGULAR,
      AuthorityEnum.MANAGER,
      AuthorityEnum.ADMIN
    ],
    children: [
      {
        name: 'my-dashboard',
        label: 'My Dashboard',
        isHovered: false,
        route: `/${RoutesEnum.DASHBOARD_REGULAR_USER_PATH}`,
        visibleTo: [
          AuthorityEnum.REGULAR,
          AuthorityEnum.MANAGER,
          AuthorityEnum.ADMIN
        ],
        isChild: true
      },
      {
        name: 'admin-dashboard',
        label: 'Admin Dashboard',
        isHovered: false,
        route: `/${RoutesEnum.ADMIN_DASHBOARD_PATH}`,
        visibleTo: [AuthorityEnum.ADMIN, AuthorityEnum.MANAGER],
        isChild: true
      }
    ]
  },
  {
    name: 'my-profile',
    label: 'My Profile',
    icon: 'user',
    width: '30',
    height: '30',
    isHovered: false,
    route: `/${RoutesEnum.PROFILE_PATH}`,
    visibleTo: [
      AuthorityEnum.REGULAR,
      AuthorityEnum.MANAGER,
      AuthorityEnum.ADMIN
    ]
  },
  // ipp-300 Hide Profile Management until its requested
  // {
  //   name: 'profile-management',
  //   label: 'Profile Management',
  //   icon: 'cv',
  //   width: '29',
  //   height: '37',
  //   isHovered: false,
  //   visibleTo: [
  //     AuthorityEnum.REGULAR,
  //     AuthorityEnum.MANAGER,
  //     AuthorityEnum.ADMIN
  //   ],
  //   children: [
  //     {
  //       name: RoutesEnum.MY_CV_PATH,
  //       label: 'My CV',
  //       isHovered: false,
  //       route: `/${RoutesEnum.PROFILE_MANAGEMENT_PATH}/${RoutesEnum.MY_CV_PATH}`,
  //       visibleTo: [
  //         AuthorityEnum.REGULAR,
  //         AuthorityEnum.MANAGER,
  //         AuthorityEnum.ADMIN
  //       ],
  //       isChild: true
  //     }
  //   ]
  // },
  {
    name: 'make-a-request',
    label: 'Make a Request',
    icon: 'list',
    width: '34',
    height: '34',
    isHovered: false,
    visibleTo: [
      AuthorityEnum.REGULAR,
      AuthorityEnum.MANAGER,
      AuthorityEnum.ADMIN
    ],
    children: [
      {
        name: 'vacation',
        label: 'Vacation',
        isHovered: false,
        route: `/${RoutesEnum.MAKE_REQUEST_PATH}/${RoutesEnum.VACATION_REQUEST_PATH}`,
        visibleTo: [
          AuthorityEnum.REGULAR,
          AuthorityEnum.MANAGER,
          AuthorityEnum.ADMIN
        ],
        isChild: true
      },
      {
        name: 'working-from-office',
        label: 'Working from Office',
        isHovered: false,
        route: `/${RoutesEnum.MAKE_REQUEST_PATH}/${RoutesEnum.WORKIN_FORM_OFFICE_REQUEST_PATH}`,
        visibleTo: [
          AuthorityEnum.REGULAR,
          AuthorityEnum.MANAGER,
          AuthorityEnum.ADMIN
        ],
        isChild: true
      },
      {
        name: 'onsite',
        label: 'Onsite',
        isHovered: false,
        route: `/${RoutesEnum.MAKE_REQUEST_PATH}/${RoutesEnum.ONSITE_REQUEST_PATH}`,
        visibleTo: [
          AuthorityEnum.REGULAR,
          AuthorityEnum.MANAGER,
          AuthorityEnum.ADMIN
        ],
        isChild: true
      },
      {
        name: 'book-nomad-room',
        label: 'Book Nomad Room',
        isHovered: false,
        route: `/${RoutesEnum.MAKE_REQUEST_PATH}/${RoutesEnum.BOOK_NOMAD_ROOM_REQUEST_PATH}`,
        visibleTo: [
          AuthorityEnum.REGULAR,
          AuthorityEnum.MANAGER,
          AuthorityEnum.ADMIN
        ],
        isChild: true
      }
    ]
  },
  {
    name: 'nomad-offices',
    label: 'Nomad Offices',
    icon: 'nomad',
    width: '26',
    height: '30',
    isHovered: false,
    visibleTo: [
      AuthorityEnum.REGULAR,
      AuthorityEnum.MANAGER,
      AuthorityEnum.ADMIN
    ],
    children: []
  },
  {
    name: 'mindit.io-team',
    label: 'mindit.io Team',
    icon: 'team',
    width: '34',
    height: '34',
    isHovered: false,
    visibleTo: [
      AuthorityEnum.REGULAR,
      AuthorityEnum.MANAGER,
      AuthorityEnum.ADMIN
    ],
    children: [
      {
        name: 'mindit-team',
        label: 'MinditTeam',
        isHovered: false,
        route: `/${RoutesEnum.MINDIT_TEAM_TAB_PATH}/${RoutesEnum.MINDIT_TEAM_PATH}`,
        visibleTo: [
          AuthorityEnum.REGULAR,
          AuthorityEnum.MANAGER,
          AuthorityEnum.ADMIN
        ],
        isChild: true
      },
      {
        name: 'mindit-org-chart',
        label: 'Organigram',
        isHovered: false,
        route: `/${RoutesEnum.MINDIT_TEAM_TAB_PATH}/${RoutesEnum.MINDIT_ORG_CHART_PATH}`,
        visibleTo: [
          AuthorityEnum.REGULAR,
          AuthorityEnum.MANAGER,
          AuthorityEnum.ADMIN
        ],
        isChild: true
      }
    ]
  },
  {
    name: 'calendars',
    label: 'Calendars',
    icon: 'calendar',
    width: '30',
    height: '32',
    isHovered: false,
    visibleTo: [
      AuthorityEnum.REGULAR,
      AuthorityEnum.MANAGER,
      AuthorityEnum.ADMIN
    ],
    children: [
      {
        name: 'classic-calendar',
        label: 'Classic Calendar',
        isHovered: false,
        route: `/${RoutesEnum.CALENDARS_PATH}/${RoutesEnum.CLASSIC_CALENDAR_PATH}`,
        visibleTo: [
          AuthorityEnum.REGULAR,
          AuthorityEnum.MANAGER,
          AuthorityEnum.ADMIN
        ],
        isChild: true
      },
      {
        name: 'selection-calendar',
        label: 'Selection Calendar',
        isHovered: false,
        route: `/${RoutesEnum.CALENDARS_PATH}/${RoutesEnum.SELECTION_CALENDAR_PATH}`,

        visibleTo: [
          AuthorityEnum.MANAGER,
          AuthorityEnum.ADMIN
        ],
        isChild: true
      }
    ]
  },
  {
    name: 'display general',
    label: 'Display General',
    icon: 'display-general',
    width: '30',
    height: '26',
    isHovered: false,
    route: `/${RoutesEnum.DISPLAY_GENERAL_PATH}`,
    visibleTo: [AuthorityEnum.MANAGER, AuthorityEnum.ADMIN]
  },
  {
    name: 'administration',
    label: 'Administration',
    icon: 'admin',
    width: '34',
    height: '34',
    isHovered: false,
    visibleTo: [AuthorityEnum.ADMIN, AuthorityEnum.MANAGER],
    children: [
      {
        name: 'holidays',
        label: 'Holidays',
        isHovered: false,
        route: `/${RoutesEnum.HOLIDAYS_ADMIN_PATH}`,
        visibleTo: [AuthorityEnum.ADMIN],
        isChild: true
      },
      {
        name: 'office',
        label: 'Office',
        isHovered: false,
        route: `/${RoutesEnum.LOCATION_ADMIN_PATH}`,
        visibleTo: [AuthorityEnum.ADMIN],
        isChild: true
      },
      {
        name: 'nomad',
        label: 'Nomad',
        isHovered: false,
        route: `/${RoutesEnum.NOMAD_ADMIN_PATH}`,
        visibleTo: [AuthorityEnum.ADMIN],
        isChild: true
      },
      {
        name: 'requests',
        label: 'Requests',
        isHovered: false,
        route: `/${RoutesEnum.REQUEST_ADMINISTRATION}`,
        visibleTo: [AuthorityEnum.ADMIN, AuthorityEnum.MANAGER],
        isChild: true
      },
      {
        name: 'company',
        label: 'Company',
        isHovered: false,
        route: `/${RoutesEnum.COMPANY_ADMIN_PATH}`,
        visibleTo: [AuthorityEnum.ADMIN],
        isChild: true
      },
      {
        name: 'team',
        label: 'Team',
        isHovered: false,
        route: `/${RoutesEnum.TEAM_ADMIN_PATH}`,
        visibleTo: [AuthorityEnum.ADMIN],
        isChild: true
      },
      {
        name: 'user',
        label: 'User',
        isHovered: false,
        route: `/${RoutesEnum.USER_ADMIN_PATH}`,
        visibleTo: [AuthorityEnum.ADMIN],
        isChild: true
      }
    ]
  },
  {
    name: 'all-requests',
    label: 'All Requests',
    icon: 'all-requests',
    width: '34',
    height: '34',
    isHovered: false,
    route: `/${RoutesEnum.ALL_REQUESTS}`,
    visibleTo: [AuthorityEnum.MANAGER, AuthorityEnum.ADMIN]
  }
];
